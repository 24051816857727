<template>
  <div>
    <v-content>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm10 md6 lg8>
            <v-card class="elevation-2 pa-10 mt-16">
              <div class="text-center">
                <v-icon size="150"> mdi-account-circle </v-icon>
              </div>
              <h1 class="text-left mt-10">Change Password</h1>
              <v-card-text>
                <v-form
                  @submit.prevent="submit"
                  id="check-login-form"
                  class="my-5"
                >
                  <v-text-field
                    class="mb-8"
                    outlined
                    id="password"
                    v-model="old_password"
                    :error="!!(errors && errors.detail)"
                    :error-messages="
                      errors && errors.detail ? errors.detail : null
                    "
                    :hide-details="!(errors && errors.detail)"
                    label="Old Password"
                    type="password"
                  ></v-text-field>
                  <v-text-field
                    class="mb-8"
                    outlined
                    id="password"
                    v-model="new_password"
                    :error="!!(errors && errors.detail)"
                    hide-details
                    label="New Password"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="px-16 mx-auto"
                  @click="$router.push({ name: 'Dashboard' })"
                  x-large
                  depressed
                  color="secondary"
                >
                  Back
                </v-btn>
                <v-btn
                  type="submit"
                  form="check-login-form"
                  class="px-16 mx-auto"
                  :loading="loading"
                  :disabled="loading"
                  @click="submit"
                  x-large
                  depressed
                  color="primary"
                  >Change</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    old_password: "",
    new_password: "",
    loading: false,
    errors: null,
  }),
  components: {},
  methods: {
    submit() {
      this.loading = true;
      this.errors = true;
      this.$http({
        method: this.$store.getters["auth/changePassword"].method,
        url: this.$store.getters["auth/changePassword"].url,
        headers: this.$store.getters["auth/changePassword"].headers,
        data: {
          old_password: this.old_password,
          new_password: this.new_password,
        },
      })
        .then(() => {
          this.$router.push({ name: "Dashboard" });
        })
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
